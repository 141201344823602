import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { Index } from "elasticlunr"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from '@fortawesome/free-solid-svg-icons';
// import { Helmet } from "react-helmet"

import SEO from "../components/SEO"
import Banner from "../components/Banner"
import Layout from "../components/layout/Layout"
// import Form from "../components/Form"
import Button from "../components/Button"
import { SectionWrapper, SectionInnerWrapper, Row, Col } from "../components/Wrapper"

const SearchPage = ({ data }) => {
    var showdown = require('showdown'),
        converter = new showdown.Converter();

    const [query, setQuery] = useState("");
    const [results, setResults] = useState([]);
    const [index, setIndex] = useState(null);

    const getOrCreateIndex = () => {
        return index ? index : Index.load(data.siteSearchIndex.index);
    };

    const search = (event) => {
        const input = event.target.value;
        setQuery(input);

        const searchIndex = getOrCreateIndex();
        setIndex(searchIndex);

        const searchResult = searchIndex.search(input, {}).map(({ ref }) => searchIndex.documentStore.getDoc(ref));
        if (searchResult.length === 0) {
            setResults([]);
        } else {
            setResults(searchResult.map(x => {
                const sentenceRegex = new RegExp("(" + input + "|[A-Z][^\\.]*" + input + ")([^\\.]*)\\.", "gi");
                const wordRegex = new RegExp(input, "gi");
                const maxWords = 40;
                let body = x.body;

                // Show sentence with input word at the beginning
                const sentenceMatch = sentenceRegex.exec(body);
                if (sentenceMatch) {
                    body = body.slice(sentenceMatch.index);
                }

                // Cut body paragraph if it is too long
                let arr = body.split(' ');
                if (arr.length > maxWords) {
                    body = body.split(' ').slice(0, maxWords).join(' ');
                    body += " ...";
                }

                // Make input word bold
                let wordMatch, searchBody = body, count = 0;
                while ((wordMatch = wordRegex.exec(searchBody)) !== null) {
                    const wordIndex = wordMatch.index + count * 7;
                    body = body.slice(0, wordIndex) + "<b>" + body.slice(wordIndex, wordIndex + input.length)
                        + "</b>" + body.slice(wordIndex + input.length);
                    count += 1;
                }

                return { ...x, body: `<p>${body}</p>` };
            }));
        }
    };

    return (
        <Layout>
            <SEO
                title={`All Ins Agency - Search`}
                description={`All Ins Agency`}
            />
            <Banner heading={data.allStrapiBanner.edges[0].node.heading} subHeading={data.allStrapiBanner.edges[0].node.subheading} trail={data.allStrapiBanner.edges[0].node.name} trailLink="/search" image={data.site.siteMetadata.apiUrl + data.allStrapiBanner.edges[0].node.fields.imageUrl} />
            <SectionWrapper>
                <SectionInnerWrapper>
                    <div>
                        <Row className="w-full mb-4">
                            <input type="text" placeholder="Search" value={query} onChange={search} className="w-full" />
                            <div className="ml-2">
                                <Button onClick={search} size="sm" className="px-10">
                                    <FontAwesomeIcon icon={faSearch} />
                                </Button>
                            </div>
                        </Row>
                        {results && results.length > 0 ?
                            <div className="text-xs mb-4">
                                <span>Search result{results.length > 1 && "s"} (</span>
                                <span>{results.length}</span>
                                <span>)</span>
                            </div>
                            :
                            query && <div className="text-sm">No Results</div>
                        }
                        <ul>
                            {results.map((x, i) => (
                                <li key={i}>
                                    {x.title && <Link to={x.path} className="font-semibold">{x.title}</Link>}
                                    {x.body &&
                                        <div
                                            className="text-sm"
                                            dangerouslySetInnerHTML={{ __html: converter.makeHtml(x.body) }}
                                        />
                                    }
                                </li>
                            ))}
                        </ul>
                    </div>
                    {/* <Helmet>
                        <script async src="https://cse.google.com/cse.js?cx=267fecde051ce51de"></script>
                    </Helmet>
                    <div className="gcse-search"></div> */}
                </SectionInnerWrapper>
            </SectionWrapper>
        </Layout >
    )
}

export default SearchPage

export const searchQuery = graphql`
    query SearchQuery {
        allStrapiBanner(filter: {page: {eq: "/search"}}, sort: {fields: fields___sortindexnum, order: ASC}) {
            edges {
                node {
                    subheading
                    heading
                    name
                    page
                    fields {
                        imageUrl
                        sortindexnum
                    }
                }
            }
        }
        siteSearchIndex {
            index
            pages
        }
        site {
            siteMetadata { 
                apiUrl
            }
        }
    }
`